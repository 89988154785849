.mobile-page-fade-in {
	animation: fadeIn ease-out 100ms; 
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.form-check-label {
  cursor: pointer;
}